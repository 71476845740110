@media screen {
  :not(.table-responsive) > .table > thead {
    position: sticky;
    z-index: 2;
    inset-block-start: 56px;
    background-color: $white;
    box-shadow: 0 20px 20px -25px var(--border-color);
  }
}

.table {
  &-nowrap thead th {
    white-space: nowrap;
  }

  thead th {
    word-wrap: break-word;

    & > ul {
      @include list-unstyled;

      margin-bottom: 0;

      /* AzF soll/ist */
      li {
        margin-bottom: 0;

        &:nth-child(2) {
          color: $info;
        }
      }
    }
  }

  &--editable {
    /* #1069 AzF #1077 cluster_kunden_tabelle.html */
    .form-group,
    input[type="text"] {
      text-align: center;
      margin: 0 auto;
      float: none;
      display: inline-block;
    }

    ul {
      @include list-unstyled;

      margin-bottom: 0;
    }

    .label,
    .badge {
      font-weight: 400;
      vertical-align: middle;
    }

    td:first-child {
      a {
        display: inline-block;
      }
    }

    /* #1069 #1077 */
    tbody td {
      .fklasse {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.3rem;
        font-weight: 700;

        &:hover {
          background: fadeout($indigo, 90%);
          border-radius: $border-radius;
        }
      }
    }
  }

  tr.disabled {
    td {
      background: $gray-300;
    }
  }

  // Tabelle mit Aktionen in der 1. Spalte
  // Knöpfe der Aktionen nebeneinander
  .cell-actions {
    display: flex;
    justify-content: space-around;
  }

  // caption oben
  &.caption-top {
    caption {
      caption-side: top;
    }
  }

  // #2978 ZAS-Artikel
  .zas & {
    td:last-child {
      ul {
        @include list-unstyled;

        margin-bottom: 0;
      }
      details {
        max-height: 120px;
        overflow-y: auto;
      }
    }
  }

  // responsive
  &-responsive {
    scrollbar-width: 1rem;
    scrollbar-color: $primary $secondary;

    &::-webkit-scrollbar {
      width: 1rem;
      height: 1rem;
    }
    &::-webkit-scrollbar-track {
      background-color: $secondary;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 2px;
    }
  }
}

/* Symbole in der 1. Spalte der Tabelle (Aktionen) in der fixen Breite */
td:first-child {
  .btn {
    margin-bottom: 0.2em;
  }
}

/* Startseite */

#table__kenzahlen {
  td {
    text-align: right;

    &:nth-child(1),
    &:nth-child(2) {
      text-align: left;
    }

    &:last-child {
      width: 6%;
    }
  }
}

#table__cluster-overview {
  th:nth-child(n + 2)::first-letter {
    display: inline-block;
  }

  td:nth-child(n + 2),
  th:nth-child(n + 2) {
    text-align: center;
  }

  th:nth-child(n + 2) {
    span {
      font-style: italic;
      color: $gray-400;
    }
  }

  td:nth-child(n + 2) {
    span {
      //margin-right: 0.5em;
      width: 20%;
      display: inline-block;
      padding: 0.1em 0.3em;
      border-radius: 2px;

      &:hover {
        background: $gray-300;
        cursor: pointer;
      }

      &:last-child {
        font-style: italic;
        color: $gray-400;
        margin-right: 0;
      }
    }
  }

  thead {
    th:nth-child(n + 2)::first-letter {
      color: $gray-400;
      margin-right: 0.5em;
    }
  }
}

// Bootstrap-Vue override
.b-table {
  // --- Header sort styling ---
  > thead,
  > tfoot {
    > tr {
      > th {
        &[aria-sort] {
          // `&.sorting`
          white-space: nowrap;
          padding-right: 0.5rem;

          //   position: relative;

          // Up/down sort=null indicator
          &::before {
            padding-left: 0.5rem;

            // position: absolute;
            // right: 2px;
          }
        }
      }
    }
  }
}
