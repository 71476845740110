// Navbar
.navbar-dark {
  .navbar-brand {
    color: #fff !important;
  }

  .dropdown-menu {
    background-color: $dark !important;
  }
}

.dropdown-menu {
  min-width: 15rem;
}