/* Cluster-ID A bis G */
.cluster {
  &::before {
    position: absolute;
    left: 3px;
    top: 3px;
    color: $gray-500;
  }
}

.filialklasse {
  min-width: 80px;
}

/* Artikelliste */
.sort {
  white-space: nowrap;
  display: inline-block;
}

.img-artikelbild-thumbnail {
  width: 80px;
  max-width: none;
  height: auto;
}

/* Artikelformular */

/* wichtige Felder markieren */
label {

  #div_id_status &,
  #div_id_preis_set-0-betrag_hap_1 &,
  #div_id_preis_set-0-betrag_vkp_1 &,
  #div_id_kundeartikel_set-0-kartnr &,
  #div_id_artikellieferant_set-0-ekp & {
    color: $warning;

    &::before {
      font-family: FontAwesome;
    }
  }
}

/* Libro Bevor-Prüfung ohne Verlauf */
.details .card {
  @extend .mb-3;

  &-danger,
  &-success {
    .card-header {
      background-image: none !important;
    }
  }
}

/* AzF & Cluster-Tabelle für Artikel */
.azf {
  padding: 2px !important;
  text-align: center;
  background: transparent !important;
}

.azf_typ_ {
  &Z {
    color: #222;
  }

  &0 {
    color: #000 !important;
  }

  &X {
    font-weight: bold;
  }

  &R {
    font-weight: bold;
  }
}

.cls-detailinfo {
  font-size: 80%;
}

.filialklasse {
  min-width: 80px;
}

// #1513 #2126 Artikel sperren
.datenquelle {
  &--cluster_artikel {

    // Cluster Artikel
    .card-header {
      background-color: lighten($orange, 30);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23fe7800' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &--stammd_artikel {

    // PhonoNet Artikel
    .card-header {
      background-color: lighten($pink, 30);
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e83e8c' fill-opacity='0.51' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &--spiele_artikel {

    // Spiele
    .card-header {
      background-color: lighten($yellow, 20);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23ffc107' fill-opacity='0.32'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &--sonstiges_artikel {

    // Sonstiges
    .card-header {
      background-color: lighten($cyan, 30);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' viewBox='0 0 16 32'%3E%3Cg fill='%2317a2b8' fill-opacity='0.2'%3E%3Cpath fill-rule='evenodd' d='M0 24h4v2H0v-2zm0 4h6v2H0v-2zm0-8h2v2H0v-2zM0 0h4v2H0V0zm0 4h2v2H0V4zm16 20h-6v2h6v-2zm0 4H8v2h8v-2zm0-8h-4v2h4v-2zm0-20h-6v2h6V0zm0 4h-4v2h4V4zm-2 12h2v2h-2v-2zm0-8h2v2h-2V8zM2 8h10v2H2V8zm0 8h10v2H2v-2zm-2-4h14v2H0v-2zm4-8h6v2H4V4zm0 16h6v2H4v-2zM6 0h2v2H6V0zm0 24h2v2H6v-2z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &--buch_vlb_artikel {

    // Buch-VLB
    .card-header {
      background-color: lighten($purple, 30);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%236f42c1' fill-opacity='0.2'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &--schmidtartikel {

    // #2126 schmidt.artikel Tabelle wird mit dem slugify Filter
    // zu der Klasse .datenquelle--schmidtartikel umgeschrieben
    .card-header {
      background-color: lighten($green, 30);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='65' height='65' viewBox='0 0 20 20'%3E%3Cg fill-opacity='0.34'%3E%3Cpolygon fill='%2360c060' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%2360c060' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

// Shöpping
// Index
.shoepping {
  &--index {
    .grid {
      grid-auto-rows: repeat(auto-fit, 1fr);
      height: calc(100% - 6rem);
      align-content: start;

      a {
        font-weight: 600;
        font-size: $h5-font-size;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    nav h3 {
      grid-column: -1/1;
      display: flex;
      align-self: center;
    }

    .list-group-item+.list-group-item {
      border-top-width: 1px;
    }
  }
}

// #2625 Recherche-Knöpfe
.btn--recherche {
  &__lieferbarkeit {
    background-color: var(--violet-1);
  }

  &__unito {
    background-color: var(--violet-2);
  }

  &__shoepping {
    background-color: var(--violet-3);
  }

  &__ebay {
    background-color: var(--violet-4);
    color: #fff;
  }

  &__ebay-delta {
    background-color: var(--violet-5);
    color: #fff;
  }
}

// #2885 GDS Kaskade
.gds {
  &--kaskade {
    .list-group-item {
      gap: var(--gap);

      .badge {
        margin-inline-start: var(--gap);
      }

      &:hover {
        cursor: grab;
      }
    }
  }
}

// #2883
.ebayrahmbedformular {
  #div_id_lieferzeit>div {
    @extend .grid;
    @extend .grid-auto;
  }
}

// allgemeine Indexseite
.index {

  nav,
  h1 {
    @include grid-reset;
  }

  main>section {
    @include grid;
    @include gridAuto;

    article {
      @extend .card;
      @extend .card-body;
      @extend .list-group;
      @extend .list-group-flush;
      @include flexColumn;

      justify-content: flex-start;
      align-items: center;
      padding: 0;

      h2 {
        margin-block: var(--gap);
      }

      >a {
        @extend .list-group-item;
        @extend .list-group-item-action;

        gap: 0.5rem;
      }
    }

    gap: calc(var(--gap) * 2);
  }

  // #3103 Bevor-Berechnungen
  &.stathand--berechnung-index {
    article {
      position: relative;

      >div:not(.grid, .d-block) {
        @include flex;
        @extend .list-group-item;
        @extend .list-group-item-action;

        justify-content: flex-end;
        // align-items: center;
        gap: var(--gap);

        &:active,
        &:focus,
        &:hover {
          background-color: unset;
        }

        // #3103
        form:not(.grid, .d-block) {
          display: flex;
          flex: 1;
          gap: calc(var(--gap) * 0.5);
          align-items: center;
          justify-content: flex-start;

          >div {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: calc(var(--gap) * 0.5);
            flex: 1;
          }
        }

        button {
          white-space: nowrap;
        }

        >time {
          font-weight: 500;
          text-align: left;
          font-size: var(--font-size-0);

          ul {
            @extend .list-group;
            @extend .list-group-flush;

            li {
              @extend .list-group-item;

              white-space: nowrap;
              padding: calc(var(--gap) * 0.3);
            }
          }
        }
      }

      // #3154 Schritte der Berechnung aufzählen
      &::after {
        position: absolute;
        top: 0;
        left: 1rem;
        font-size: 3rem;
        font-weight: 600;
        color: var(--color-disabled);
        z-index: 0;
        pointer-events: none;
      }

      // 1 bis 6 loop für <article>
      @for $i from 1 through 6 {
        &:nth-of-type(#{$i})::after {
          content: "#{$i}";
        }
      }
    }
  }

  // Projektkunden index
  &.projektkunden {
    --col: 20rem;

    main>section {
      @include gridAutoFit;

      article {
        padding: var(--gap);
        justify-content: center;
        gap: calc(var(--gap) * 0.5);

        a {
          border: none;
          padding: 0;

          &:active,
          &:focus,
          &:hover {
            background-color: transparent;
          }
        }

        svg {
          width: 4rem;

          &.icon {
            height: 1.4rem;
            width: auto;
          }
        }
      }
    }
  }
}

// Stathand Formulare in Tabellen
.stathand {
  table {
    >thead>th {
      max-width: 20ch;
    }

    >tbody>tr>td {
      .card--submit {
        display: grid;
        gap: 0.2rem;
        position: absolute;
        top: 2.5rem;
        min-width: 20ch;
        width: 100%;
        z-index: 4;
      }

      // Spalten mit Zahlen sind rechtsgebunden
      &.text-right {

        select,
        input {
          justify-self: flex-end;
        }

        .card--submit {
          right: 3px;
        }
      }
    }
  }
}