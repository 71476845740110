/* Forms */
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  @extend .form-control;
}

.form-inline {
  justify-content: flex-start;
  align-items: flex-end;

  @extend .mb-3;

  div,
  input,
  button,
  a.btn {
    margin-right: 0.25rem;
    align-self: end;
  }

  label {
    font-size: $small-font-size;

    @extend .col-form-label;
    @extend .text-muted;

    margin-top: 0.25rem;
    justify-content: start;
  }

  .form-group,
  .input-group section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: flex-start;

    label {
      margin-bottom: -0.3rem;
    }
  }

  .input-group {
    .input-group-prepend {
      align-items: flex-end;
    }

    div,
    input {
      margin-right: 0;
      border-radius: unset;
    }

    input {
      border-left: 0;
    }

    input[type="date"] {
      border-radius: 0 $border-radius $border-radius 0;
    }
  }
}

.kundenformular,
.lieferantenformular {
  textarea {
    height: 5rem;
  }
}

.no-label {
  margin-top: 32px;
}

.edit,
.inplace_form {
  display: block;
  padding: 0 0.2em;

  &:hover {
    cursor: pointer;
  }

  .inplace_ {
    &field {
      max-width: 4em;
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }

    &save,
    &cancel {
      margin-top: 2px;
    }
  }

  // button {
  //     &.inplace_save {
  //     }
  // }
}

.form select + input {
  margin-right: 5px;
}

/* Filter und Neu-Felder */
.form-group {
  position: relative;

  & > div {
    position: relative;
  }

  &--filter {
    input {
      background-color: $gray-300 !important;
    }
  }

  &--neu {
    input {
      background-color: $gray-400 !important;
    }
  }
}

/* Filter-Cards */
.filter {
  margin: 1rem auto;

  > .card-body {
    padding: 0.5rem 1rem 0;
  }
}

// Loginfenster
.login {
  .wrapper > main > section {
    display: flex;
    flex-direction: column;
    height: 100%;

    .card {
      margin: auto;
      border-radius: $border-radius-lg;
      box-shadow: rgb(0 0 0 / 25%) 0 15px 30px -12px;
    }
  }
}
