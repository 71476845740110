.proto-level- {
  /* Protokolltabelle */
  // &10,
  // &20,
  // &30,
  // &40,
  // &50,
  // &60 { }

  &60 td:nth-child(2) {
    background-color: $danger;
  }

  &50 td:nth-child(2) {
    background-color: desaturate(lighten($danger, 10), 10%);
  }

  &40 td:nth-child(2) {
    background-color: desaturate(lighten($danger, 20), 20%);
  }

  &30 td:nth-child(2) {
    background-color: desaturate(lighten($danger, 30), 30%);
  }

  &20 td:nth-child(2) {
    background-color: desaturate(lighten($danger, 40), 40%);
  }

  &10 td:nth-child(2) {
    background-color: desaturate(lighten($danger, 50), 50%);
  }
}
