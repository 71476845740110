// open props
// https://open-props.style/

@import '../../../../node_modules/open-props/src/props.gray';
@import '../../../../node_modules/open-props/src/props.fonts';
@import '../../../../node_modules/open-props/src/props.sizes';

// colors
:root {
  --gap: 1rem;
  --color-light: var(--gray-1);
  --color-dark: var(--gray-10);
  --border-color: var(--gray-3);
  --color-disabled: var(--gray-2);
  --col: 30rem;
  --accent: #521edd;

  color-scheme: light;
  accent-color: var(--accent);
}

$enable-flex: true;
$enable-shadows: true;
$enable-rounded: true;
$enable-gradients: false;
$input-btn-line-height: 1.35;
$print-page-size: a4;
$pagination-active-color: var(--color-dark);
$pagination-active-bg: #fff;
$pagination-active-border-color: var(--gray-2);

// Bootstrap-Vue
// $b-table-sort-icon-null: "\2195" !default; // Up-Down arrow
// $b-table-sort-icon-ascending: "\2193" !default; // Down arrow
// $b-table-sort-icon-descending: "\2191" !default; // Up arrow
$b-table-sort-icon-margin-left: 0.5em;

// Typography
$font-family-sans-serif: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans",
  "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-bms: "Source Sans 3 Variable", $font-family-base;

// Choices
$choices-font-size-lg: var(--font-size-2);
$choices-font-size-md: var(--font-size-1);
$choices-font-size-sm: var(--font-size-0);
$choices-selector: 'choices' !default;
$choices-guttering: 24px !default;
$choices-border-radius: 2px;
$choices-border-radius-item: 3px;
$choices-bg-color: transparent;
$choices-bg-color-disabled: #eaeaea !default;
$choices-bg-color-dropdown: #fff !default;
$choices-text-color: #333 !default;
$choices-keyline-color: #ddd !default;
$choices-primary-color: #00bcd4 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;
