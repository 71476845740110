.card {
  &-header {
    h2 {
      margin-bottom: 0;
    }
  }
}

// Karten-Grid
.card-deck {
  @include grid;

  grid-template-columns: repeat(auto-fill, minmax(calc(15% + 8rem), 1fr));

  .card {
    margin-bottom: var(--gap);

    &-header {
      font-weight: 600;
    }
  }
}