.icon {
  display: inline-block;
  vertical-align: middle;
  width: var(--size-3); // 1rem
  height: var(--size-3);
  stroke: currentcolor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  margin-top: -0.2rem;
  margin-right: 0.2rem;

  // reset:
  .page-link &,
  .btn & {
    margin-right: unset;
  }

  .toast-header &,
  .btn & {
    margin-top: unset;
  }

  .btn-secondary,
  .btn-primary {
      &.icon-fill {
      fill: currentcolor;
    }
  }

  &.disabled {
    stroke: var(--color-disabled);
    pointer-events: none;
  }

  // inactive pagination
  .disabled a & {
    stroke: var(--color-disabled);
    pointer-events: none;
  }

  // Sizes
  .btn-sm & {
    @extend .icon--sm;
  }

  &--sm {
    width: calc(var(--size-3) * 0.8); // 0.8rem
    height: calc(var(--size-3) * 0.8);
  }

  &--md {
    width: var(--size-4); // 1.25rem
    height: var(--size-4);
  }

  &--lg {
    width: var(--size-5); // 1.5rem
    height: var(--size-5);
  }

  &--xl {
    width: var(--size-6); // 1.75rem
    height: var(--size-6);
  }

}