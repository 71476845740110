// Breadcrumbs
.wrapper > nav {
  padding: calc(var(--gap) * 0.5) var(--gap);
}

nav > .breadcrumb {
  background-color: $gray-200;

  > li {
    @extend .breadcrumb-item;
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;

    &.breadcrumb-actions {
      .btn {
        @extend .btn-light;

        margin-right: 0.2rem;
        padding: 0 0.2rem;
      }

      &::before {
        content: "—\00a0";
      }
    }
  }
}
