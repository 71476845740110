[popover] {
  transition: opacity 0.2s, transform 0.2s, display 0.2s allow-discrete;

  opacity: 0;
  transform: translateY(3rem);

  &:popover-open {
    opacity: 1;
    transform: none;
    padding: 2rem;

    @starting-style {
      & {
        opacity: 0;
        transform: translateY(-1rem);
      }
    }
  }
}

::backdrop {
  background-color: #0005;
}
