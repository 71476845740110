@mixin flex() {
  display: flex;
}

@mixin flexColumn() {
  display: flex;
  flex-direction: column;
}

@mixin grid() {
  display: flex;
  flex-flow: column wrap;
  gap: var(--gap);
  align-content: flex-start;

  @supports (grid-area: auto) {
    display: grid;
  }
}

@mixin gridAuto() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
  }
}

@mixin gridAutoFit() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fit, minmax(var(--col), 1fr));
  }
}

@mixin grid-reset {
  grid-column: -1/1;
}

.grid {
  @include grid;
}

.grid-auto,
.grid--auto {
  @include gridAuto;
}

.grid-reset {
  @include grid-reset;
}
