//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  display: flex;

  @include list-unstyled;
  @include border-radius;

  justify-content: center;

  @extend .mt-2;
  @extend .mb-2;

  > a,
  .endless_page_current,
  .endless_separator {
    position: relative;
    display: block;
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -$pagination-border-width;
    line-height: $pagination-line-height;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;

    &:hover {
      color: $pagination-hover-color;
      text-decoration: none;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }

    &:focus {
      z-index: 2;
      outline: 0;
      box-shadow: $pagination-focus-box-shadow;
    }

    &:first-child {
      .page-link {
        margin-left: 0;

        @include border-left-radius($border-radius);
      }
    }

    &:last-child {
      .page-link {
        @include border-right-radius($border-radius);
      }
    }
  }

  > .current {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
      cursor: default;
    }
  }

  > .disabled {
    &,
    &:hover,
    &:focus {
      color: $pagination-disabled-color;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
      cursor: not-allowed;
    }
  }
}

//
// Sizing
//

.pagination-lg {
  @include pagination-size(
    $pagination-padding-y-lg,
    $pagination-padding-x-lg,
        $font-size-lg,
        $line-height-lg,
        $border-radius-lg
    );
}

.pagination-sm {
  @include pagination-size(
    $pagination-padding-y-sm,
    $pagination-padding-x-sm,
        $font-size-sm,
        $line-height-sm,
        $border-radius-sm
    );
}
