:root {
  // Open Props violet
  // https://open-props.style/
  --violet-0: #f3f0ff;
  --violet-1: #e5dbff;
  --violet-2: #d0bfff;
  --violet-3: #b197fc;
  --violet-4: #9775fa;
  --violet-5: #845ef7;
  --violet-6: #7950f2;
  --violet-7: #7048e8;
  --violet-8: #6741d9;
  --violet-9: #5f3dc4;
}

// stylelint-disable
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$bms: #1c2597;
$bms-print: #7d5bb3;
$blue: $bms;
$austria: #521edd;
$debug: #e83e8c;

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
    'austria': $austria,
    'debug': $debug,
  ),
  $colors
);

$primary: $bms;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$error: $red; // für Django
$debug: $debug; // Devel-Installationen

// #1697 Überfällige Bestellungen
$ueberfaellig: desaturate(lighten($danger, 40), 20%);

// #2169 Gleiche Mengen
$gleichemengen: desaturate(lighten($success, 40), 20%);

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'error': $danger,
  ),
  $theme-colors
);
// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// Toasts
$toast-background-color: rgba($white, 0.9);
$toast-header-background-color: rgba($white, 0.9);
