/* Debug */
body.debug {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB3aWR0aD0nMTMwJyBoZWlnaHQ9JzY1Jz4KCTxkZWZzPgoJCTxnIGlkPSdsaW5lcyc+CgkJCTxsaW5lIHgxPScwJyB5MT0nMCcgeDI9JzI2JyB5Mj0nMzIuNScgc3Ryb2tlLXdpZHRoPScxJyBzdHJva2U9JyNlY2VjZWMnLz4KCQkJPGxpbmUgeDE9JzE5LjUnIHkxPScwJyB4Mj0nNDUuNScgeTI9JzMyLjUnIHN0cm9rZS13aWR0aD0nMScgc3Ryb2tlPScjZmJmYmZiJy8+CgkJCTxsaW5lIHgxPSczOScgeTE9JzAnIHgyPSc2NScgeTI9JzMyLjUnIHN0cm9rZS13aWR0aD0nMScgc3Ryb2tlPScjZjVmNWY1Jy8+CgkJPC9nPgoJPC9kZWZzPgoJPHJlY3QgeD0nLTEwJScgeT0nLTEwJScgd2lkdGg9JzEyMCUnIGhlaWdodD0nMTIwJScgZmlsbD0nI2ZmZicvPgoJPHVzZSB4bGluazpocmVmPScjbGluZXMnIHg9JzY1JyB5PSczMi41JyAvPgoJPHVzZSB4bGluazpocmVmPScjbGluZXMnIHg9JzY1JyB5PSctMzIuNScgdHJhbnNmb3JtPSdtYXRyaXgoMSAwIDAgLTEgMCAwKScvPgoJPHVzZSB4bGluazpocmVmPScjbGluZXMnICAvPgoJPHVzZSB4bGluazpocmVmPScjbGluZXMnIHg9JzAnIHk9Jy02NScgdHJhbnNmb3JtPSdtYXRyaXgoMSAwIDAgLTEgMCAwKScvPiAgCjwvc3ZnPg==);

  // background-attachment: fixed;
  // #debug-badge {
  //     position: fixed;
  //     z-index: 0;
  //     right: 2rem;
  //     bottom: 2rem;
  //     padding: 0;
  //     margin: 0;
  //     i {
  //         padding: 0;
  //         margin: 0;
  //         color: $debug;
  //         &:hover {
  //             @extend .fa-spin;
  //         }
  //     }
  // }
  .btn-debug {
    background: $debug;
  }

  .navbar-brand {
    color: $white;
  }

  .navbar-light {
    background-color: #f8deea !important;
  }

  nav .breadcrumb {
    color: $gray-600;
    background-color: $gray-400;

    a,
    .active,
    > li + li::before {
      color: $gray-600;
    }
  }

  // which labels don’t have a for attribute and which inputs don’t have an id
  // https://twitter.com/tanishaaa03/status/1275505781459181568

  // input:not([type='button']):not([type='reset']):not([type='submit']):not([aria-labelledby]):not([id]),
  // label:not([for]) {
  //     outline: 2px dashed red;
  // }
}
