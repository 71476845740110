.dl-horizontal {
  @include make-row;

  dt {
    hyphens: auto;

    @include make-col-ready;

    @include media-breakpoint-up(md) {
      @include make-col(5);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }

  dd {
    @include make-col-ready;

    @include media-breakpoint-up(md) {
      @include make-col(7);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }
}
