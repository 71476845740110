.sysnachr-prio- {
  /* Systemnachrichten */
  &40,
  &50,
  &60 {
    color: #fff;
  }

  &60 td {
    background-color: $info !important;
  }

  &50 td {
    background-color: desaturate(lighten($info, 5), 10%) !important;
  }

  &40 td {
    background-color: desaturate(lighten($info, 10), 20%) !important;
  }

  &30 td {
    background-color: desaturate(lighten($info, 20), 30%) !important;
  }

  &20 td {
    background-color: desaturate(lighten($info, 25), 40%) !important;
  }

  &10 td {
    background-color: desaturate(lighten($info, 30), 50%) !important;
  }

  &0 td {
    background-color: desaturate(lighten($info, 35), 50%) !important;
  }
}

// Kein Javascript? Meldung einschalten.
.alert--no-js {
  .no-js & {
    display: block;
    visibility: visible;
  }

  .js & {
    display: none;
    visibility: hidden;
  }
}

// Alter Browser (ohne cssgrid)? Meldung.
.alert--legacybrowser {
  .js.no-cssgrid & {
    display: block;
    visibility: visible;
  }

  .js.cssgrid & {
    display: none;
    visibility: hidden;
  }
}

/* Messages */
.messages {
  h4 {
    text-transform: uppercase;
  }
}
