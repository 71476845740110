// more button stuff
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &:not(.btn-sm) {
    min-height: 35px; // fix collapsing flex buttons
  }
}

// vue spinner
.btn > span {
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;

  div {
    height: 1.3rem;

    .spinner-border {
      height: 1.3rem;
      width: 1.3rem;
    }
  }
}
