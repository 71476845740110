html {
  height: 100%;
}

body {
  height: calc(100vh - 3rem);
}

.wrapper {
  @include grid;

  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr); // breite Tabellen overflow fix
  margin-top: 3rem;
  height: 100%;

  // body footer
  & > footer {
    font-size: 90%;
    padding: var(--gap);
  }
}

#top {
  scroll-margin-top: 3rem;
}

main {
  height: 100%;
  position: relative;
}

.grid {
  @include grid;

  article {
    @extend .col;
  }

  &--auto {
    @include gridAuto;
  }

  &--half {
    grid-template-columns: repeat(2, 1fr);
  }

  &--third {
    grid-template-columns: repeat(3, 1fr);
  }

  &--reset,
  &--stretch {
    @include grid-reset;
  }

  .row {
    // .row mit Submit/Cancel-Knöpfen bei Formularen
    @include grid-reset;
    @extend .col;
  }

  // Formulare
  fieldset {
    @extend .card;
    @extend .card-body;

    // padding: 1rem 0;

    legend {
      width: auto;
      padding: 0 0.5rem;
    }
  }

  .btn,
  input,
  button {
    @extend .mr-1;
  }

  // Lieferantenformular: Konditionen in automatischen Spalten, for fun & profit
  &--stretch {
    fieldset {
      @extend .grid;
      @include gridAuto;
    }
  }
}

// Gap: --gap * number
.gap {
  @for $i from 1 through 6 {
    &--#{$i} {
      gap: calc(var(--gap) * #{$i});
    }
  }
}