@include SourceSans.faces(
  $subsets: all,
  $weights: (
    300,
    400,
    600,
    700,
  ),
  $styles: all,
  $directory: "../fonts/SourceSans3"
);

body {
  font-family: $font-family-bms;
  font-size: var(--font-size-1);
  // font-size-adjust: ch-width 0.5;
  font-style: normal;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  hyphens: auto;
  display: flex;
  gap: 0.3rem;
}

b,
strong {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}
